import { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export default function ScrollToTop() {
  const { pathname, hash } = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.

      history.listen(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      })

      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 0)
    } else {
      window.scrollTo(0, 0)
    }
  }, [pathname, hash, history])

  return null
}
