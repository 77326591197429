import React from 'react'
import { Switch, Route, Link } from 'react-router-dom'

import Header from 'components/Header'
import Footer from 'components/Footer'
import Container from 'components/Container'

import Landing from 'pages/Landing'
import AboutUs from 'pages/AboutUs'
import Product from 'pages/Product'
import ContactUs from 'pages/ContactUs'
import PrivacyPolicy from 'pages/PrivacyPolicy'

import styles from './App.module.scss'

const App = () => {
  const NotFound = () => {
    return (
      <div className={styles.NotFound}>
        <Container>
          <div className={styles.msgWrapper}>
            <div className={styles.msg}>ไม่พบหน้าที่คุณต้องการ</div>
            <Link to='/'>กลับสู่หน้าหลัก</Link>
          </div>
        </Container>
      </div>
    )
  }
  return (
    <div className={styles.App}>
      <Header />
      <div className={styles.site}>
        <Switch>
          <Route exact path='/' component={Landing} />
          <Route path='/home' component={Landing} />
          <Route path='/about-us' component={AboutUs} />
          <Route exact path='/product' component={Product} />
          <Route path='/product/:slug' component={Product} />
          <Route path='/contact-us' component={ContactUs} />
          <Route path='/privacy-policy' component={PrivacyPolicy} />
          <Route component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </div>
  )
}

export default App
