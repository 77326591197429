import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import Container from 'components/Container'
import ButtonText from 'components/ButtonText/ButtonText'

import styles from './FloatingContent.module.scss'

type FloatingContent = {
  onPrev: () => void
  onNext: () => void
  data?: any
  current: number
}
export default function FloatingContent({ onPrev, onNext, data, current }: FloatingContent) {
  const [currentData, setCurrentData] = useState(0)
  const [fadedOut, setFadedOut] = useState(false)

  useEffect(() => {
    setFadedOut(true)
    setTimeout(() => {
      setCurrentData(current)
      setFadedOut(false)
    }, 250)
    return () => {}
  }, [current])
  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.scrollDown}>
          <img src={require('./assets/scroll-down.svg').default} alt='Scroll Down' />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.control}>
            <div onClick={onPrev}>
              <div>{'<'}</div>
            </div>
            <div onClick={onNext}>
              <div>{'>'}</div>
            </div>
          </div>
          <div className={classNames(styles.counter, fadedOut ? styles.fadedOut : '')}>{currentData + 1}</div>
          <div className={classNames(styles.content, fadedOut ? styles.fadedOut : '')}>
            <h2>{data[currentData].title}</h2>
            <p>{data[currentData].content}</p>
          </div>
          {data[currentData].buttonTitle && (
            <div className={classNames(styles.button, fadedOut ? styles.fadedOut : '')}>
              <ButtonText.Default to={data[currentData].buttonTo}>{data[currentData].buttonTitle}</ButtonText.Default>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}
