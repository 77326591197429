import ContactBox from 'components/ContactBox'
import React from 'react'
import Introduction from './components/Introduction'

export default function Landing() {
  return (
    <div>
      <section>
        <Introduction />
      </section>
      <ContactBox />
    </div>
  )
}
