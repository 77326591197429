import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import useWindowScrollPosition from '@rooks/use-window-scroll-position'

import FullScreenMenu from 'components/FullScreenMenu/FullScreenMenu'
import ButtonText from 'components/ButtonText/ButtonText'

import styles from './Header.module.scss'

export const NAV_LINKS = [
  {
    name: 'เกี่ยวกับเรา',
    path: '/about-us',
  },
  {
    name: 'ผลิตภัณฑ์และบริการ',
    path: '/product',
  },
  {
    name: 'ลูกค้าของเรา',
    path: '/home#our-customer',
  },
  {
    name: 'ติดต่อเรา',
    path: '/contact-us',
  },
]

const ACTION_BTNS = [
  { name: process.env.REACT_APP_PHONE, href: `tel:${process.env.REACT_APP_PHONE}` },
  { name: 'LINE@WACON', href: '#', type: 'LINE' },
]

export default function Header() {
  const location = useLocation()
  const { scrollY } = useWindowScrollPosition()
  const [isCollapsed, setIsCollapsed] = useState(true)

  const scrollableHeaderRoutes = ['/']
  const scrolledHeader =
    scrollY > 0 ? styles.scrolled : !_.includes(scrollableHeaderRoutes, location.pathname) ? styles.scrolled : null

  const toggleFullScreenMenu = useCallback(() => {
    setIsCollapsed(!isCollapsed)
  }, [isCollapsed])

  return (
    <div className={styles.wrapper}>
      <FullScreenMenu isShow={!isCollapsed} links={NAV_LINKS} onNavigate={() => setIsCollapsed(true)} />
      <div className={classNames(styles.container, scrolledHeader, !isCollapsed && styles.hidden)}>
        <div className={styles.menuWrapper}>
          <div
            className={classNames(styles.menu, scrolledHeader, !isCollapsed && styles.active)}
            onClick={toggleFullScreenMenu}
          >
            {isCollapsed ? (
              <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fillRule='evenodd' clipRule='evenodd'>
                <path d='M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z' fill='#1040e2' />
                <path d='M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z' />
              </svg>
            ) : (
              <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fillRule='evenodd' clipRule='evenodd'>
                <path d='M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z' />
              </svg>
            )}
          </div>
          <Link to='/' onClick={() => setIsCollapsed(true)}>
            <img className={styles.logo} src={require('assets/images/logo-wacon.png').default} alt='WACON' />
          </Link>
          <Link
            className={classNames(styles.brandTitle, !isCollapsed && styles.shown)}
            to='/'
            onClick={() => setIsCollapsed(true)}
          >
            <div>วัชระคอนกรีต</div>
          </Link>
        </div>
        <div className={classNames(styles.linkWrapper, scrolledHeader)}>
          {_.map(NAV_LINKS, (item, index) => (
            <Link key={index} className={styles.link} to={item.path} onClick={() => setIsCollapsed(true)}>
              {item.name}
            </Link>
          ))}
        </div>
        <div className={styles.buttonWrapper}>
          {_.map(ACTION_BTNS, (item, index) => {
            return item.type === 'LINE' ? (
              <a href='https://lin.ee/Wu0s1jE' target='_blank' rel='noreferrer'>
                <img src='https://scdn.line-apps.com/n/line_add_friends/btn/th.png' alt='เพิ่มเพื่อน' height='36' />
              </a>
            ) : (
              <a key={index} href={item.href} className={styles.contactItem}>
                <ButtonText.Default>{item.name}</ButtonText.Default>
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}
