import React from 'react'
import _ from 'lodash'

import Container from 'components/Container'

import styles from './Footer.module.scss'

export default function Footer() {
  const MENUS = [
    {
      title: 'ผลิตภัณฑ์',
      links: [
        { title: 'ปูนซีเมนต์', to: '/product/cement' },
        { title: 'คอนกรีตผสมเสร็จ', to: '/product/mixed-concrete' },
        { title: 'แผ่นพื้นสำเร็จรูป', to: '/product/prestressed-concrete-slab' },
        { title: 'เสาเข็มคอนกรีตอัดแรง', to: '/product/prestressed-concrete-piles' },
      ],
    },
    {
      title: 'Terms',
      links: [{ title: 'Privacy Policy', to: '/privacy-policy' }],
    },
    {
      title: 'ติดต่อเรา',
      links: [
        { title: process.env.REACT_APP_PHONE, to: `tel:${process.env.REACT_APP_PHONE}`, phone: true },

        { title: '073-420-299', to: `tel:073-420-299`, phone: true },
        { title: '081-957-5519', to: `tel:081-957-5519`, phone: true },
        { title: 'Facebook', to: 'https://facebook.com/watcharaconcrete/', target: '_blank' },
        { title: 'Line', to: 'https://lin.ee/Wu0s1jE' },
      ],
    },
  ]

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div className={styles.outerWrapper}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <div className={styles.brand}>
              <img className={styles.logo} src={require('assets/images/logo-wacon.png').default} alt='WACON' />
              <div>หจก. วัชระคอนกรีต</div>
            </div>
            <div className={styles.location}>{process.env.REACT_APP_ADDRESS}</div>
            <img className={styles.decor} src={require('./assets/factory.svg').default} alt='factory' />
          </div>
          <div className={styles.right}>
            <div className={styles.grid}>
              {_.map(MENUS, (item, index) => {
                return (
                  <div className={styles.linkGroup} key={index}>
                    <div className={styles.linkGroupTitle} key={index}>
                      {item.title}
                    </div>
                    <div className={styles.linksWrapper}>
                      {_.map(item.links, (link, linkIndex) => {
                        return link.phone ? (
                          <a className={styles.phone} key={linkIndex} href={link.to}>
                            {link.title}
                          </a>
                        ) : (
                          <a key={linkIndex} href={link.to} target={link.target} rel='noreferrer'>
                            {link.title}
                          </a>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
              <div className={styles.copyRight}>
                <div>wacharaconcrete.com</div>
                <br />
                Copyright ©2021 <br />
                All Rights Reserved.
              </div>
            </div>
            <div className={styles.goToTop} onClick={goToTop}>
              <img src={require('./assets/arrow-up.svg').default} alt='go to top' />{' '}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
