import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './ButtonText.module.scss'

type ButtonText = {
  children?: any
  onClick?: () => void
  to?: string
}

const Default = ({ children, onClick, to }: ButtonText) => {
  return to ? (
    <Link to={to} className={classNames(styles.wrapper, styles.light)} onClick={onClick}>
      <div className={styles.content}>{children}</div>
      <div className={styles.decor} />
    </Link>
  ) : (
    <div className={classNames(styles.wrapper, styles.light)} onClick={onClick}>
      <div className={styles.content}>{children}</div>
      <div className={styles.decor} />
    </div>
  )
}

export default { Default }
