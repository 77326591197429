import classNames from 'classnames'
import Container from 'components/Container'
import _ from 'lodash'
import React from 'react'
import { useTable } from 'react-table'

import styles from './ProductConcreteSlab.module.scss'

const SpecA = () => {
  const QUALITY_SPECS_DATA = React.useMemo(
    () => [
      {
        width: '30 x 5',
        length: '0.05-3.00',
        count: '4',
        size: '4',
        load: '300',
      },
      {
        width: '30 x 5',
        length: '3.05-3.55',
        count: '5',
        size: '4',
        load: '300',
      },
      {
        width: '30 x 5',
        length: '3.60-4.05',
        count: '6',
        size: '4',
        load: '300',
      },
      {
        width: '30 x 5',
        length: '4.10-5.00',
        count: '7',
        size: '4',
        load: '300',
      },
    ],
    []
  )

  const QUALITY_SPECS_COLUMNS = React.useMemo(
    () => [
      {
        Header: 'hidden',
        Footer: 'ทุกชนิดทุกขนาด ความยาวตามสั่ง',
        columns: [
          {
            Header: (
              <p>
                ขนาดหน้าตัด
                <br />
                <span>กว้าง (cm.) x หนา (cm.)</span>
              </p>
            ),
            accessor: 'width',
            className: styles.headerMain,
          },
          {
            Header: (
              <p>
                ความยาว
                <br />
                <span>(m.)</span>
              </p>
            ),
            accessor: 'length',
            className: styles.headerMain,
          },
          {
            Header: (
              <p>
                จำนวนเส้นลวด
                <br />
                <span>(No.of PC Wire)</span>
              </p>
            ),
            accessor: 'count',
            className: styles.headerMain,
          },
        ],
      },
      {
        Header: 'shown',
        Footer: '',
        columns: [
          {
            Header: (
              <p>
                ขนาดเส้นลวด
                <br />
                <span>(mm.)</span>
              </p>
            ),
            accessor: 'size',
            className: styles.headerMain,
          },
        ],
      },
      {
        Header: 'hidden',
        Footer: 'ตามสั่ง',
        columns: [
          {
            Header: (
              <p>
                Live Load
                <br />
                <span>(kg/m²)</span>
              </p>
            ),
            accessor: 'load',
            className: styles.headerMain,
          },
        ],
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = useTable({
    columns: QUALITY_SPECS_COLUMNS,
    data: QUALITY_SPECS_DATA,
  } as any)
  return (
    <table {...getTableProps()} className={styles.table}>
      <thead>
        {headerGroups.map((headerGroup) => {
          const isHidden = _.first(_.get(headerGroup, 'headers'))?.Header === 'hidden'
          return (
            !isHidden && (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className={classNames(styles.headerCell)}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            )
          )
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row)
          return (
            <tr className={classNames(styles.row, index % 2 === 0 ? styles.odd : styles.even)} {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td className={styles.cell} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        {footerGroups.map((group, index) => {
          return (
            index !== 0 && (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => {
                  return (
                    <td
                      className={classNames(
                        styles.cell,
                        styles.footerCell,
                        column.Footer === 'ทุกชนิดทุกขนาด ความยาวตามสั่ง' && styles.center
                      )}
                      {...column.getFooterProps()}
                    >
                      {column.render('Footer')}
                    </td>
                  )
                })}
              </tr>
            )
          )
        })}
      </tfoot>
    </table>
  )
}

export default function ProductConcreteSlab() {
  return (
    <div className={styles.wrapper}>
      <img className={styles.imageFluid} src={require('./assets/scg-pre-mixed.jpeg').default} alt='mixed-concrete' />
      <div className={styles.specificationWrapper}>
        <Container>
          <div className={styles.specificationContainer}>
            <h2>รายละเอียด</h2>
            <p>
              แผ่นพื้นตันคอนกรีตเสริมลวดอัดแรง แข็งแรง ทนทานท้องเรียบ ช่วยประหยัดเวลาและแรงงาน
              ควบคุมคุณภาพทุกขั้นตอนโดยผลิตจากโรงงาน ช่วยลดจำนวนแรงงานลงได้ ตลอดระยะเวลากว่า 30 ปี
              วัชระคอนกรีตได้สั่งสมประสบการณ์ ความรู้ ความเชี่ยวชาญ จนเป็นที่ไว้วางใจตั้งแต่อดีตจนถึงปัจจุบัน
              ทำให้ลูกค้ามั่นใจได้ว่าจะได้รับสินค้าและบริการที่มีคุณภาพทุกครั้งที่ตัดสินใจใช้วัชระคอนกรีต
              เหมาะสำหรับงานประเภท บ้านพักอาศัยอาคารขนาดเล็กอพาร์ทเมนต์ทาวน์โฮม
            </p>
            <h2>สินค้าและบริการ</h2>
            <div className={styles.larger}>
              <u>คุณสมบัติ</u>
            </div>
            <ul>
              <p>
                <li>
                  แข็งแรง: ผลิตจากคอนกรีตที่มีกำลังอัดสูงถึง 400 กก./ตร.ซม. ทรงลูกบาศก์ จึงทำให้แผ่นพื้นมีความแข็งแรง
                  ทนทาน และมีคุณภาพสูงกว่าแผ่นพื้นสำเร็จรูปทั่วไป
                </li>
                <li>แผ่นพื้นตันคอนกรีตอัดแรงมีความหนาที่ 5 ซม. ขนาดความกว้าง 30 ซม. และความยาวสูงสุด 5 ม.</li>
                <li>ควบคุมทุกขั้นตอนโดยทีมงานมืออาชีพ ทั้งเรื่องความแข็งแรงและความสวยงาม</li>
              </p>
            </ul>
            <div className={styles.larger}>
              <u>คำแนะนำในการใช้งานและข้อควรระวัง</u>
            </div>
            <ul>
              <p>
                <li>
                  โปรดตรวจสอบความสามารถในการรับน้ำหนักบรรทุกของแผ่นพื้นตันคอนกรีตอัดแรงให้สอดคล้องกับการใช้งานจริง
                </li>
                <li>การปรับปรุงแผ่นพื้นตันคอนกรีตอัดแรงต่างๆ เช่น การบาก การตัดขาด การเจาะหรือการใช้งานผิดประเภท</li>
                ควรปรึกษาวิศวกรผู้ออกแบบก่อนใช้งาน
              </p>
            </ul>
            <h2>ข้อมูลเชิงเทคนิค</h2>
            <div className={styles.tableWrapper}>
              <SpecA />
            </div>
            {/* <ul className={styles.remarks}>
              <li>
                กำลังอัดคอนกรีตที่ใช้เทกับหน้าไม่น้อยกว่า 210 ksc. ทรงกระบอกมาตรฐาน หรือ 240 ksc. ทรงลูกบาศก์มาตรฐาน
                และเททับหน้า 5 cm.
              </li>
              <li>ต้องมีการทำค้ำยัน 2 จุดที่ระยะ L/3</li>
            </ul> */}
          </div>
        </Container>
      </div>
    </div>
  )
}
