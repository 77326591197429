import React from 'react'

import Container from 'components/Container'
import ContactBox from 'components/ContactBox'

import styles from './ContactUs.module.scss'

export default function Product() {
  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.introduction}>
          <div>
            <h2 className={styles.heroText}>
              <span>ติดต่อเรา</span>
              <br />
              หจก. วัชระคอนกรีต
              <ContactBox contactOnly />
            </h2>
          </div>
          <div className={styles.content}>
            <p>{process.env.REACT_APP_ADDRESS}</p>
            <iframe
              className={styles.maps}
              title='Maps'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.341462998083!2d101.19410421529246!3d6.849608495049944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31b304c4a658e445%3A0x2a8dec955655453b!2swachara%20concrete!5e0!3m2!1sen!2sth!4v1632544818917!5m2!1sen!2sth'
              loading='lazy'
            />
          </div>
        </div>
      </Container>
      {/* <div>Content</div> */}
      {/* <ContactBox /> */}
    </div>
  )
}
