import Container from 'components/Container'
import _ from 'lodash'
import React from 'react'
import styles from './DirectoryBar.module.scss'

type DirectoryBarProps = {
  directory: Array<string>
}

export default function DirectoryBar({ directory }: DirectoryBarProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Container>
          <div className={styles.bulletWrapper}>
            {_.map(directory, (item, index) => {
              return <div key={index}>{item}</div>
            })}
          </div>
        </Container>
      </div>
    </div>
  )
}
