/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
// import _ from 'lodash'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import Slider from 'react-slick'

import Container from 'components/Container'

import styles from './News.module.scss'

export default function News() {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // }
  // const NEWS = [
  //   // {
  //   //   title: 'คอนกรีตผสมเสร็จ',
  //   //   description: 'คอนกรีตผสมเสร็จมาตรฐาน อุสาหกรรม ควบคุมคุณภาพโดย พนักงานที่มีประสบการณ์',
  //   //   to: '/product/concrete',
  //   //   img: require('./assets/S__6332482.jpg').default,
  //   // },
  //   // {
  //   //   title: 'คอนกรีตผสมเสร็จ',
  //   //   description: 'คอนกรีตผสมเสร็จมาตรฐาน อุสาหกรรม ควบคุมคุณภาพโดย พนักงานที่มีประสบการณ์',
  //   //   to: '/product/concrete',
  //   //   img: require('./assets/S__6332480.jpg').default,
  //   // },
  //   {
  //     title: 'คอนกรีตผสมเสร็จ',
  //     description: 'คอนกรีตผสมเสร็จมาตรฐาน อุสาหกรรม ควบคุมคุณภาพโดย พนักงานที่มีประสบการณ์',
  //     to: '/product/concrete',
  //     img: require('./assets/S__6430916.jpg').default,
  //   },
  // ]
  return (
    <div className={styles.outerWrapper}>
      <Container>
        <div className={styles.wrapper}>
          {/* <h2 className={styles.header}>ข่าวสาร / โปรโมชั่น</h2> */}
          <div className={styles.sliderWrapper}>
            {/* <Slider {...settings}>
              {_.map(NEWS, (item, index) => {
                return (
                  <div key={index} className={styles.card}>
                    <div className={styles.img}>{item.img && <img src={item.img} alt={item.title} />}</div>
                  </div>
                )
              })}
            </Slider> */}
            <div className={styles.ratio}>
              <iframe
                className={styles.player}
                src='https://www.youtube-nocookie.com/embed/yt3k3fiAhFQ'
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
