import React from 'react'
import MainSlider from 'components/MainSlider'
import Introduction from './components/Introduction'
import News from './components/News'
import Product from './components/Product'
import Gallery from './components/Gallery'
import Partner from './components/Partner'

export default function Landing() {
  return (
    <div>
      <section>
        <MainSlider />
      </section>
      <section>
        <Introduction />
      </section>
      <section>
        <News />
      </section>
      <section>
        <Product />
      </section>
      <section>
        <Gallery />
      </section>
      <section>
        <Partner />
      </section>
    </div>
  )
}
