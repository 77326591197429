import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MessengerCustomerChat from 'react-messenger-customer-chat'
import ScrollToTop from 'components/ScrollToTop'

import { Provider } from 'react-redux'
import Store from 'store'

import './index.scss'

import App from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <MessengerCustomerChat
    pageId='535816403279949'
    appId='2069211336569861'
    htmlRef='fb-chat-ref'
    // loggedInGreeting='วัชระคอนกรีตยินดีให้บริการ เริ่มแชทกับเราเพื่อสอบถามข้อมูลเพิ่มเติม'
    // loggedOutGreeting='วัชระคอนกรีตยินดีให้บริการ เริ่มแชทกับเราเพื่อสอบถามข้อมูลเพิ่มเติม'
    // greetingDialogDisplay='show'
    // shouldShowDialog='true'
  />,
  document.getElementById('fb-chat')
)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
