import React from 'react'

import _ from 'lodash'
import Container from 'components/Container'

import styles from './Partner.module.scss'

export default function Partner() {
  const PARTNER = [
    {
      title: 'ใช้ปูนเอสซีจี',
      img: require('./assets/scg-partner.png').default,
    },

    { title: 'มอก. 213-2560 คอนกรีต', img: require('./assets/standard-concrete.png').default },
    { title: 'มอก. 828-2546 แผ่นพื้น', img: require('./assets/standard-floor.png').default },
    { title: 'มอก. 396-2549 เสาเข็ม', img: require('./assets/standard-pile.png').default },
  ]
  return (
    <div>
      <Container>
        <div className={styles.content}>
          <p>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
              <path d='M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z' />
            </svg>{' '}
            <b>ปัจจุบันบริษัทมีการขยายกิจการ โดยมีพื้นที่ให้บริการครอบคลุมทั้งปัตตานี ยะลา นราธิวาส และสงขลา</b>{' '}
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
              <path d='M11 9.275c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275zm13 0c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275z' />
            </svg>
          </p>
        </div>
        <div className={styles.partnerListWrapper}>
          {_.map(PARTNER, (item, index) => {
            return (
              <div key={index}>
                <img src={item.img} alt={`partner-${index}`} />
              </div>
            )
          })}
        </div>
        <div className={styles.content}>
          <p className={styles.small}>
            ตั้งแต่ปี พ.ศ. 2555 บริษัท ปูนซีเมนต์ไทย จำกัดมหาชน ได้มอบเครื่องหมาย “endorsed brand”
            ให้กับห้างมาโดยตลอดถึงปัจจุบัน ด้วยระยะเวลาดำเนินงานกว่า 30 ปี
            ทำให้วัชระคอนกรีตมีความเชี่ยวชาญในผลิตภัณฑ์คอนกรีตทุกชนิด
          </p>
        </div>
      </Container>
    </div>
  )
}
