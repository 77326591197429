import React, { useState } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import Container from 'components/Container'

import styles from './Product.module.scss'

export default function Product() {
  const [hoverAt, setHoverAt] = useState<null | number>(null)
  const PRODUCT = [
    {
      title: 'ปูนซีเมนต์',
      description: 'ปูนซีเมนต์เพื่องานโครงสร้าง ก่ออิฐ เทพื้น ฉาบผนัง และตกแต่ง ด้วยเนื้อปูนยึดเกาะสูง',
      to: 'https://www.facebook.com/WacharaHM',
      target: '_blank',
      outside: true,
      img: require('./assets/cement.png').default,
    },
    {
      title: 'คอนกรีตผสมเสร็จ',
      description: 'คอนกรีตผสมเสร็จมาตรฐาน อุสาหกรรม ควบคุมคุณภาพโดย พนักงานที่มีประสบการณ์',
      to: '/product/mixed-concrete',
      img: require('./assets/cement2.png').default,
    },
    {
      title: 'แผ่นพื้นสำเร็จรูป',
      description: 'แผ่นพื้นคอนกรีตอัดแรงสำเร็จรูป เสริมด้วยลวดอัดแรงกำลังสูง พร้อมใช้งาน',
      to: '/product/prestressed-concrete-slab',
      img: require('./assets/floor.png').default,
    },
    {
      title: 'เสาเข็มคอนกรีตอัดแรง',
      description: 'ผลิตด้วยระบบคอนกรีตเสริมลวดอัดแรง มีให้เลือกทั้งหน้าตัดตัวไอและสีเหลี่ยมตัน',
      to: '/product/prestressed-concrete-piles',
      img: require('./assets/column.png').default,
    },
  ]
  return (
    <div id='all' className={styles.outerWrapper}>
      <Container>
        <div className={styles.wrapper}>
          <h2 className={styles.header}>ผลิตภัณฑ์ของเรา</h2>
          <div className={styles.sliderWrapper}>
            {_.map(PRODUCT, (item, index) => {
              return (
                <Link
                  onMouseOver={() => setHoverAt(index)}
                  onMouseLeave={() => setHoverAt(null)}
                  to={item.outside ? { pathname: item.to } : item.to}
                  key={index}
                  target={item.target}
                  className={classNames(styles.card, hoverAt !== null && hoverAt !== index ? styles.less : '')}
                >
                  <div className={styles.img}>{item.img && <img src={item.img} alt={item.title} />}</div>
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </Link>
              )
            })}
          </div>
        </div>
      </Container>
    </div>
  )
}
