import React, { useState } from 'react'

import _ from 'lodash'
import styles from './MainSlider.module.scss'
import FloatingContent from './components/FloatingContent'
import Background from './components/Background'

const SLIDES = [
  {
    imageUrl: require('./assets/photo-1536895058696-a69b1c7ba34f.jpeg').default,
    title: 'มั่นใจคุณภาพ “ใช้ปูนเอสซีจี”',
    content: `วัชระคอนกรีต ได้รับสัญลักษณ์รับรอง "ใช้ปูนเอสซีจี" ซึ่งเป็นปูนซีเมนต์คุณภาพสูง และเป็นวัตถุดิบหลักในการผลิต`,
  },
  {
    imageUrl: require('./assets/photo-1541888967080-c956a48eaad2.jpeg').default,
    title: 'มั่นใจคุณภาพ',
    content: `วัชระคอนกรีต ได้รับสัญลักษณ์รับรอง "ใช้ปูนเอสซีจี" ซึ่งเป็นปูนซีเมนต์คุณภาพสูง และเป็นวัตถุดิบหลักในการผลิต`,
    buttonTitle: 'ดูสินค้าทั้งหมด',
    buttonTo: '/product',
  },
]

export default function MainSlider() {
  const [current, setCurrent] = useState(0)

  const onPrev = () => {
    setCurrent(current === 0 ? _.size(SLIDES) - 1 : current - 1)
  }
  const onNext = () => {
    setCurrent(current < _.size(SLIDES) - 1 ? current + 1 : 0)
  }

  return (
    <div className={styles.wrapper}>
      {/* {_.size(SLIDES) - 1}/{current} */}
      <Background data={SLIDES} current={current} />
      <FloatingContent onPrev={onPrev} onNext={onNext} data={SLIDES} current={current} />
    </div>
  )
}
