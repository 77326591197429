import React, { useEffect } from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import ButtonText from 'components/ButtonText/ButtonText'
import styles from './FullScreenMenu.module.scss'

// const concreteFloorVideo = require('assets/videos/person-running-concrete-floor.mp4').default
// const pouringConcreteVideo = require('assets/videos/Pexels Videos 1197802 (1).mp4').default
// const concreteFloorVideo = require('assets/videos/production ID_5156396 (1).mp4').default

type FullScreenMenu = {
  isShow: boolean
  links: any
  onNavigate: () => void
}

const ACTION_BTNS = [
  { name: process.env.REACT_APP_PHONE, href: `tel:${process.env.REACT_APP_PHONE}` },
  { name: 'LINE@WACON', href: '#', type: 'LINE' },
]

export default function FullScreenMenu({ isShow = false, links = [], onNavigate = () => {} }: FullScreenMenu) {
  useEffect(() => {
    return () => {}
  }, [])
  return (
    <div className={classNames(styles.wrapper, isShow && styles.shown)}>
      <div className={styles.container}>
        <div className={styles.mainContentWrapper}>
          <div className={styles.linkWrapper}>
            {_.map(links, (item, index) => {
              return (
                <Link key={index} className={styles.link} to={item.path} onClick={onNavigate}>
                  {item.name}
                </Link>
              )
            })}
          </div>
          <div className={styles.buttonWrapper}>
            <img src={require('assets/images/support.svg').default} alt='support' className={styles.decor} />
            <div className={styles.buttonContainer}>
              {_.map(ACTION_BTNS, (item, index) => {
                return item.type === 'LINE' ? (
                  <a href='https://lin.ee/Wu0s1jE' target='_blank' rel='noreferrer'>
                    <img src='https://scdn.line-apps.com/n/line_add_friends/btn/th.png' alt='เพิ่มเพื่อน' height='36' />
                  </a>
                ) : (
                  <a key={index} href={item.href} className={styles.contactItem}>
                    <ButtonText.Default>{item.name}</ButtonText.Default>
                  </a>
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles.featuredContentWrapper}>
          {/* <div className={styles.card}>
            <video autoPlay loop muted>
              <source src={pouringConcreteVideo} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={styles.card}>
            <video autoPlay loop muted>
              <source src={concreteFloorVideo} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className={styles.card}>
            <img
              src='https://images.unsplash.com/photo-1565710545039-46c9a7d22959?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80'
              alt='Concrete'
            />
          </div>
          <div className={styles.card}>
            <img
              src='https://images.unsplash.com/photo-1511037474482-8fb9002a5fe0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=975&q=80'
              alt='Concrete'
            />
          </div>
          <div className={styles.card}>
            <img
              src='https://images.unsplash.com/photo-1548081120-ee9025b68ffd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=664&q=80'
              alt='Concrete'
            />
          </div> */}
        </div>
      </div>
    </div>
  )
}
