import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import rootReducer from './rootReducer'

export type RootState = ReturnType<typeof rootReducer>
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend().concat(logger),
})

export type AppDispatch = typeof store.dispatch

export default store as any
