import React from 'react'
import classNames from 'classnames'

import Container from 'components/Container'

import styles from './Introduction.module.scss'

export default function Introduction() {
  return (
    <div id='about-us'>
      <Container>
        <div className={styles.wrapper}>
          <div>
            <h2 className={styles.heroText}>
              <span>หจก.วัชระคอนกรีต</span>
              <br /> มุ่งเน้นคุณภาพ ของผลิตภัณฑ์ และการบริการดั่งคนสำคัญ
            </h2>
            <img src={require('./assets/neighborhood.svg').default} alt='neighborhood' />
          </div>
          <div className={styles.content}>
            {/* <p>
              ผู้ผลิตและจำหน่าย เสาเข็ม แผ่นพื้น และคอนกรีตผสมเสร็จ หนึ่งในบริษัท ที่มุ่งเน้นด้านคุณภาพของผลิตภัณฑ์
              และการบริการดั่งคนสำคัญ
            </p>
            <p>
              วัตถุดิบ ที่นำมาใช้ในการผลิต ทุกชนิดถูกคัดสรรจากแหล่งที่มีคุณภาพดีที่สุด การได้รับ การรับรองตามมาตรฐาน
              (มอก.) เป็นเครื่องหมายที่แสดงถึงคุณภาพของผลิตภัณฑ์ของเรา
            </p> */}
            <p>
              เป้าหมายของเราคือการทำให้คุณภาพชีวิตของผู้คนดียิ่งขึ้น
              โดยการนำเสนอผลิตภัณฑ์ที่มีคุณภาพออกสู่ตลาดทั้งคอนกรีตผสมเสร็จ แผ่นพื้นสำเร็จรูป และเสาเข็มอัดแรง
            </p>
            <p>
              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                <path d='M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z' />
              </svg>{' '}
              <b>ปัจจุบันบริษัทมีการขยายกิจการ โดยมีพื้นที่ให้บริการครอบคลุมทั้งปัตตานี ยะลา นราธิวาส และสงขลา</b>{' '}
              <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                <path d='M11 9.275c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275zm13 0c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275z' />
              </svg>
            </p>
            <p>
              เราเลือกใช้ปูน SCG มาตั้งแต่เริ่มก่อตั้งบริษัท เพราะคุณภาพที่เป็นที่ยอมรับ ในระดับสากลมายาวนานกว่า 100 ปี
            </p>
            <p>ผลิตภัณฑ์ทุกชนิดของวัชระคอนกรีต ได้รับเครื่องหมายรับมาตรฐานอุตสาหกรรม</p>
          </div>
        </div>
        <div className={styles.wrapperSection}>
          <div className={classNames(styles.content)}>
            {/* <p>
              เราเลือกใช้ปูน SCG มาตั้งแต่เริ่มก่อตั้งบริษัท เพราะคุณภาพที่เป็นที่ยอมรับในระดับสากลมายาวนานกว่า 100 ปี
            </p>
            <p>ผลิตภัณฑ์ทุกชนิดของวัชระคอนกรีต ได้รับเครื่องหมายรับมาตรฐานอุตสาหกรรม</p> */}
            <p>
              <b>เรายึดมั่นในความซื่อสัตย์และคุณภาพเป็นหลักในใจตั้งแต่วันแรกที่เปิดให้บริการ</b>
            </p>
            <p>
              <b>
                เราสัญญาว่าเราจะไม่หยุดพัฒนาผลิตภัณฑ์ที่มีคุณภาพ และรักษามาตรฐานความตรงต่อเวลาในการจัดส่งสินค้า
                เพื่อให้ลูกค้ามั่นใจในทุกครั้งที่ตัดสินใจใช้ผลิตภัณฑ์ของเรา...
              </b>
            </p>
          </div>
        </div>
      </Container>
    </div>
  )
}
