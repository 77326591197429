import { createSlice } from '@reduxjs/toolkit'

interface AppState {
  entities: []
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialState = {
  loading: 'idle',
} as AppState

const appSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = 'succeeded'
    },
    hideLoading: (state) => {
      state.loading = 'idle'
    },
  },
  extraReducers: () => {
    // params: builder
    // both `state` and `action` are now correctly typed
    // based on the slice state and the `pending` action creator
  },
})

export const { showLoading, hideLoading } = appSlice.actions
export default appSlice.reducer
