import Container from 'components/Container'
import _ from 'lodash'
import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

import styles from './ProductConcretePiles.module.scss'
import SpecA from './ProductConcretePilesSpecA'
import SpecB from './ProductConcretePilesSpecB'
import SpecC from './ProductConcretePilesSpecC'
import SpecD from './ProductConcretePilesSpecD'

// const images = [
//   require('./assets/S__23912488.jpg').default,
//   require('./assets/S__23912490.jpg').default,
//   require('./assets/S__23912491.jpg').default,
//   require('./assets/S__23912492.jpg').default,
//   require('./assets/S__23912493.jpg').default,
//   require('./assets/S__23912494.jpg').default,
//   require('./assets/S__23912495.jpg').default,
//   require('./assets/S__23912496.jpg').default,
//   require('./assets/S__23912497.jpg').default,
// ]

const DATA_ACCORDIAN = [
  {
    heading: (
      <div className='accordion__header'>
        <div>
          เสาเข็มอัดแรง ขนาดใหญ่ <img src={require('./assets/pile-i.png').default} alt='pile-i' />{' '}
          <img src={require('./assets/pile-square.png').default} alt='pile-i' />
        </div>
      </div>
    ),
    content: (
      <>
        <div className={styles.imageContainer}>
          <img src={require('./assets/IMG_8497.jpg').default} alt='pile' />
          <img src={require('./assets/IMG_8500.jpg').default} alt='pile' />
        </div>
        <div className={styles.larger}>
          <u>รายละเอียด</u>
        </div>
        <p>
          เสาเข็มคอนกรีตอัดแรงของ หจก.วัชระคอนกรีต ผลิตด้วยระบบคอนกรีตเสริมลวดอัดแรง
          มีให้เลือกทั้งหน้าตัดตัวไอและสีเหลี่ยมตัน สินค้าและบริการ
        </p>
        <div className={styles.tableWrapper}>
          <SpecA />
          <SpecB />
        </div>
        <div className={styles.larger}>
          <u>เหมาะสำหรับงานประเภท</u>
        </div>
        <p>บ้านพักอาศัย อาคารพาณิชย์ อาคารสำนักงาน โรงงานอุตสาหกรรม</p>
        <div className={styles.larger}>
          <u>ข้อมูลด้านการใช้งานผลิตภัณฑ์ และข้อกำหนด</u>
        </div>
        <p>
          ความยาวของเสาเข็มผลิตตามความประสงค์ของผู้ซื้อ ความยาวของเสาเข็ม สามารถผลิตสูงสุดตามความต้องการได้
          ด้วยรูปแบบเสาเข็มต่อเชื่อม
        </p>
      </>
    ),
  },
  {
    heading: (
      <div className='accordion__header'>
        <div>
          เสาเข็มอัดแรง ขนาดเล็ก <img src={require('./assets/pile-i.png').default} alt='pile-i' />{' '}
          <img src={require('./assets/pile-square.png').default} alt='pile-i' />
        </div>
      </div>
    ),
    content: (
      <>
        <div className={styles.larger}>
          <u>รายละเอียด</u>
        </div>
        <p>
          เสาเข็มคอนกรีตอัดแรงของ หจก.วัชระคอนกรีต ผลิตด้วยระบบคอนกรีตเสริมลวดอัดแรง
          มีให้เลือกทั้งหน้าตัดตัวไอและสี่เหลี่ยมตัน
        </p>
        <div className={styles.tableWrapper}>
          <SpecC />
          <SpecD />
        </div>
        <div className={styles.larger}>
          <u>เหมาะสำหรับงานประเภท</u>
        </div>
        <p>
          รั้วบ้านพักอาศัย กำแพงกันดินสไลด์ ต่อเติมอาคารขนาดเล็ก
          <br />
          * แข็งแรงกว่า: ด้วยขนาดเส้นรอบรูปที่มากกว่า(เสารูปตัวไอ) จึงทำให้เสาเข็มคอนกรีตอัดแรง
          รับน้ำหนักได้มากกว่าเสาเข็มอื่น ๆ ทั่วไป
          <br />* ใช้งานง่าย: สามารถใช้คนยกและขนย้ายเสาเข็มได้ ความยาว3-4เมตร ที่เป็นที่นิยมใช้ทำรั้ว มีน้ำหนัก 83-110
          กิโลกรัม
        </p>
      </>
    ),
  },
  {
    heading: (
      <div className='accordion__header'>
        <div>
          เสารั้ว รูปเกือกม้า อัดแรง <img src={require('./assets/pile-horseshoe.png').default} alt='pile-i' />
        </div>
      </div>
    ),
    content: (
      <>
        <p>
          เสารั้วใช้ลวดอัดแรง และคอนกรีตผสมที่สามารถเสริมความแข็งแกร่งให้กับเสารั้วให้มีความแข็งแรง ทนทาน ไม่แตกหักง่าย
          แทนการใช้เหล็กกลมธรรมดา หจก. วัชระคอนกรีตมีประสบการณ์ทำแผ่นพื้นสำเร็จรูปกว่า30ปี จึงใช้เทคโนโลยีเดียวกันนี้
          ทำให้สามารถมั่นใจได้ว่ารั้วลวดหนามที่ผลิตด้วยลวดอัดแรงจะมีมาตรฐาน แข็งแรง ทนทาน
        </p>
        <div className={styles.larger}>
          <u>ข้อมูลพื้นฐาน</u>
        </div>
        <p>
          - มีขนาด 1.50 ม., 2.00ม. และ 2.50ม.
          <br />* รั้วลวดหนามหน้าตัดเกือกม้า (มีรูสำหรับใส่ลวดหนามได้ทั้งหมด 5 รู โดยแต่ละรูมีระยะห่างกัน 30 ซม.
          เสริมด้วยลวดอัดแรง PC Wire 2 เส้นเพื่อเพิ่มความแข็งแรงให้กับรั้วบ้าน)
        </p>
        <div className={styles.larger}>
          <u>ข้อมูลเชิงเทคนิค</u>
        </div>
        <p>
          - ใช้คอนกรีตกำลังอัด 400 กก./ตร.ซม. (ทดสอบด้วยก้อนตัวอย่างแบบลูกบาศก์) หรือ 350 กก./ตร.ม.
          (ทดสอบด้วยก้อนตัวอย่างแบบทรงกระบอก)
          <br />* ใช้เหล็กลวดอัดแรง PC Wire ขนาดของเส้นผ่านศูนย์กลาง 4 มิลลิเมตร
          <br />* ใช้เหล็กลวดอัดแรงถูกดึงด้วยแรงดึง 70 % ของแรงดึงสูงสุดก่อนการเทคอนกรีต
        </p>
      </>
    ),
  },
]

export default function ProductConcretePiles() {
  return (
    <div className={styles.wrapper}>
      <img className={styles.imageFluid} src={require('./assets/S__6824806.jpg').default} alt='mixed-concrete' />
      <div className={styles.specificationWrapper}>
        <Container>
          <div className={styles.specificationContainer}>
            <h2>รายละเอียด</h2>
            {/* <p>
              เสาเข็มคอนกรีตอัดแรงของ หจก.วัชระคอนกรีต ผลิตด้วยระบบคอนกรีตเสริมลวดอัดแรง
              มีให้เลือกทั้งหน้าตัดตัวไอและสี่เหลี่ยมตัน
            </p> */}

            {/* <h2>สินค้าและบริการ</h2>
            <ul>
              <li>เสาเข็มไอ</li>
              <div>
                <ul>
                  <li>เสาเข็มไอ ขนาด 35 x 35 ซม. (ความยาว 6 - 28 ม.)</li>
                  <li>เสาเข็มไอ ขนาด 40 x 40 ซม. (ความยาว 6 - 28 ม.)</li>
                  <li>เสาเข็มไอ ขนาด 45 x 45 ซม. (ความยาว 6 - 28 ม.)</li>
                </ul>
              </div>

              <li>เสาเข็มสี่เหลี่ยมตัน</li>
              <div>
                <ul>
                  <li>เสาเข็มสี่เหลี่ยมตัน ขนาด 18 x 18 ซม. (ความยาว 4 - 18 ม.)</li>
                  <li>เสาเข็มสี่เหลี่ยมตัน ขนาด 22 x 22 ซม. (ความยาว 4 - 21 ม.)</li>
                  <li>เสาเข็มสี่เหลี่ยมตัน ขนาด 26 x 26 ซม. (ความยาว 6 - 24 ม.)</li>
                  <li>เสาเข็มสี่เหลี่ยมตัน ขนาด 30 x 30 ซม. (ความยาว 6 - 24 ม.)</li>
                  <li>เสาเข็มสี่เหลี่ยมตัน ขนาด 35 x 35 ซม. (ความยาว 6 - 28 ม.)</li>
                  <li>เสาเข็มสี่เหลี่ยมตัน ขนาด 40 x 40 ซม. (ความยาว 6 - 28 ม.)</li>
                  <li>เสาเข็มสี่เหลี่ยมตัน ขนาด 45 x 45 ซม. (ความยาว 6 - 28 ม.)</li>
                </ul>
              </div>
            </ul> */}

            {/* <p>
              <div className={styles.larger}>
                <u>เหมาะสำหรับงานประเภท</u>
              </div>
              <br />
              บ้านพักอาศัย อาคารพาณิชย์ อาคารสำนักงาน โรงงานอุตสาหกรรม
            </p>
            <p>
              <div className={styles.larger}>
                <u>ข้อมูลด้านการใช้งานผลิตภัณฑ์ และข้อกำหนด</u>
              </div>
              <br />
              ความยาวของเสาเข็มผลิตตามความประสงค์ของผู้ซื้อ ความยาวของเสาเข็ม สามารถผลิตสูงสุดตามความต้องการได้
              ด้วยรูปแบบเสาเข็มต่อเชื่อม
            </p> */}
            {/* <ul>
              <li>เสาเข็มขนาดเล็ก</li>
              <div>
                <ul>
                  <li>เสาเข็มไอ ขนาด 18 x 18 ซม. (ความยาว 1.50 , 2.00 และ 2.50 ม.)</li>
                </ul>
              </div>
            </ul> */}

            {/* <p>
              <div className={styles.larger}>
                <u>เหมาะสำหรับงานประเภท</u>
              </div>
              <br />
              รั้วบ้านพักอาศัย ต่อเติมอาคารขนาดเล็ก
              <br />
              <br />
              <div className={styles.larger}>
                <u>คุณสมบัติ</u>
              </div>
              <br />* แข็งแรงกว่า: ด้วยขนาดเส้นรอบรูปที่มากกว่า
              จึงทำให้เสาเข็มคอนกรีตอัดแรงรับน้ำหนักได้มากกว่าเสาเข็มอื่นๆทั่วไป
              <br />* ใช้งานง่าย: สามารถใช้คนยกและขนย้ายเสาเข็มได้ ความยาว 3 - 4 เมตร ที่เป็นที่นิยมใช้ทำรั้ว มีน้ำหนัก
              83 - 110 กิโลกรัม
            </p> */}

            <Accordion allowZeroExpanded>
              {_.map(DATA_ACCORDIAN, (item, index) => (
                <AccordionItem key={index}>
                  <AccordionItemHeading>
                    <AccordionItemButton>{item.heading}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>{item.content}</AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
          {/* <div className={styles.gallery}>
            {_.map(images, (item, index) => {
              return (
                <div>
                  <img key={index} src={item} alt='concrete pile' />
                </div>
              )
            })}
          </div> */}
        </Container>
      </div>
    </div>
  )
}
