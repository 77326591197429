import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import Container from 'components/Container'

import styles from './ContactBox.module.scss'

type ContactBoxProps = {
  contactOnly?: boolean
}

const CONTACT_POINT = [
  {
    contactId: 'LINE',
    icon: require('./assets/line.png').default,
    contactDescription: (
      <p>
        เพิ่มเพื่อน
        <br />
        @WACON
      </p>
    ),
    href: 'https://lin.ee/Wu0s1jE',
    target: '_blank',
  },
  {
    contactId: 'FACEBOOK',
    icon: require('./assets/facebook.png').default,
    contactDescription: (
      <p>
        ติดตาม
        <br />
        @watcharaconcrete
      </p>
    ),
    href: 'https://facebook.com/watcharaconcrete/',
    target: '_blank',
  },
  {
    contactId: 'PHONE',
    icon: require('./assets/call.svg').default,
    contactDescription: (
      <p>
        โทรหาเรา
        <br />
        {process.env.REACT_APP_PHONE}
      </p>
    ),
    href: `tel:${process.env.REACT_APP_PHONE}`,
  },
]

export default function ContactBox({ contactOnly }: ContactBoxProps) {
  return (
    <div className={classNames(styles.wrapper, contactOnly && styles.contactOnly)}>
      <Container>
        <div className={classNames(!contactOnly && styles.container)}>
          {!contactOnly && (
            <div className={styles.description}>
              <h2>สนใจผลิตภัณฑ์ของเรา ?</h2>
              <img src={require('./assets/contact.svg').default} alt='contact' />
            </div>
          )}
          <div className={styles.contactList}>
            {_.map(CONTACT_POINT, (item, index) => {
              return (
                <a key={index} href={item.href} target={item.target} rel='noreferrer' className={styles.contactItem}>
                  <img src={item.icon} alt={item.contactId} />
                  <div>{item.contactDescription}</div>
                </a>
              )
            })}
          </div>
        </div>
      </Container>
    </div>
  )
}
