import _ from 'lodash'
import React from 'react'

import styles from './Background.module.scss'

type Background = {
  data?: any
  current: number
}

export default function Background({ data, current }: Background) {
  return (
    <div className={styles.wrapper}>
      {_.map(data, (item, index) => (
        <img
          key={index}
          src={item.imageUrl}
          alt='Hero Slider'
          className={current < parseInt(index, 10) ? styles.up : current > parseInt(index, 10) ? styles.down : ''}
        />
      ))}
    </div>
  )
}
