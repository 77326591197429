import React, { useEffect } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'

import Container from 'components/Container'
import DirectoryBar from 'components/DirectoryBar'
import ContactBox from 'components/ContactBox'
import ButtonText from 'components/ButtonText/ButtonText'

import AllProduct from '../Landing/components/Product'

import ProductMixedConcrete from './components/ProductMixedConcrete'

import styles from './Product.module.scss'
import ProductConcreteSlab from './components/ProductConcreteSlab'
import ProductConcretePiles from './components/ProductConcretePiles'

const SLUG_MAP = {
  'mixed-concrete': 'คอนกรีตผสมเสร็จ',
  'prestressed-concrete-slab': 'แผ่นพื้นสำเร็จรูป',
  'prestressed-concrete-piles': 'เสาเข็มคอนกรีตอัดแรง',
}

interface ParamTypes {
  slug: keyof typeof SLUG_MAP
}

export default function Product() {
  const { pathname } = useLocation()
  const { slug } = useParams<ParamTypes>()
  const history = useHistory()

  const PRODUCT_DATA = [
    {
      productId: 'all',
      productName: 'ผลิตภัณฑ์และบริการ',
      productIntroduction: 'หจก. วัชระคอนกรีต',
      productDescription: (
        <>
          <p>
            คอนกรีตผสมเสร็จของ หจก. วัชระคอนกรีต มีการควบคุมคุณภาพคอนกรีตในทุกขั้นตอนผ่านมาตรฐานอุตสาหกรรม มอก. 213-2552
            ทั้งในระหว่างการผลิตการขนส่งและระหว่างการเทคอนกรีต โดยพนักงานควบคุมคุณภาพที่มีประสบการณ์
            และสามารถให้คำแนะนำและคำปรึกษาในเรื่องคอนกรีตผสมเสร็จได้
          </p>
          <div className={styles.productButtonGroup}>
            <ButtonText.Default onClick={() => history.push(`${pathname}#all`)}>ดูผลิตภัณฑ์ของเรา</ButtonText.Default>
          </div>
        </>
      ),
      component: <AllProduct />,
    },
    {
      productId: 'mixed-concrete',
      productName: 'คอนกรีตผสมเสร็จ',
      productIntroduction: 'วัชระคอนกรีต มุ่งเน้นคุณภาพ ของผลิตภัณฑ์ และการบริการดั่งคนสำคัญ',
      productDescription: (
        <>
          <p>
            คอนกรีตผสมเสร็จของ หจก. วัชระคอนกรีต มีการควบคุมคุณภาพคอนกรีตในทุกขั้นตอนผ่านมาตรฐานอุตสาหกรรม มอก. 213-2552
            ทั้งในระหว่างการผลิตการขนส่งและระหว่างการเทคอนกรีต โดยพนักงานควบคุมคุณภาพที่มีประสบการณ์
            และสามารถให้คำแนะนำและคำปรึกษาในเรื่องคอนกรีตผสมเสร็จได้
          </p>
          <p>นอกจากนี้ ยังมีบริการออกแบบส่วนผสมคอนกรีต (Mix Design) ให้ตรงตามความต้องการของลูกค้าอีกด้วย</p>
        </>
      ),
      component: <ProductMixedConcrete />,
    },
    {
      productId: 'prestressed-concrete-slab',
      productName: 'แผ่นพื้นสำเร็จรูป',
      productIntroduction: 'วัชระคอนกรีต มุ่งเน้นคุณภาพ ของผลิตภัณฑ์ และการบริการดั่งคนสำคัญ',
      productDescription: (
        <>
          <p>
            สำหรับงานที่ต้องการคุณภาพ ประหยัดเวลาและค่าใช้จ่าย ขอแนะนำให้ใช้แผ่นพื้นคอนกรีตอัดแรงของ หจก.วัชระคอนกรีต
            เนื่องจากมีการควบคุมคุณภาพของผลิตภัณฑ์จากโรงงานที่ผลิตตามมาตรฐานอตุสาหกรรม มอก. 828-2546
            และคอนกรีตมีกำลังต้านทานแรงอัดไม่ต่ำกว่า 300 ksc. เมื่อทำการถ่ายแรง และไม่ต่ำกว่า 400 ksc. เมื่ออายุ 28 วัน
          </p>
        </>
      ),
      component: <ProductConcreteSlab />,
    },
    {
      productId: 'prestressed-concrete-piles',
      productName: 'เสาเข็มคอนกรีตอัดแรง',
      productIntroduction: 'วัชระคอนกรีต มุ่งเน้นคุณภาพ ของผลิตภัณฑ์ และการบริการดั่งคนสำคัญ',
      productDescription: (
        <>
          <p>
            เพื่อรากฐานที่มั่นคง ขอแนะนำให้ใช้เสาเข็มคอนกรีตอัดแรงของ หจก. วัชระคอนกรีต เนื่องจากมี มอก.
            การควบคุมคุณภาพของผลิตภัณฑ์จากโรงงานที่ผลิตมาตรฐานอุตสาหกรรม 396 - 2549
            และคอนกรีตมีกำลังต้านทานแรงอัดไม่ต่ำกว่า 300 ksc. เมื่อทำการถ่ายแรง และไม่ต่ำกว่า 400 ksc. เมื่ออายุ 28 วัน
          </p>
        </>
      ),
      component: <ProductConcretePiles />,
    },
  ]

  const directoryTree = ['ผลิตภัณฑ์และบริการ', SLUG_MAP[slug] ? SLUG_MAP[slug] : 'ทั้งหมด']
  const current = _.find(PRODUCT_DATA, { productId: slug })
    ? _.find(PRODUCT_DATA, { productId: slug })
    : _.find(PRODUCT_DATA, { productId: 'all' })

  useEffect(() => {
    if (!current) {
      history.push('/product')
    }

    return () => {}
  }, [current, history])

  return (
    <div className={styles.wrapper}>
      <DirectoryBar directory={directoryTree} />
      {current?.productId !== 'all' && (
        <Container>
          <div className={styles.introduction}>
            <div>
              <h2 className={styles.heroText}>
                <span>{current?.productName}</span>
                <br /> {current?.productIntroduction}
              </h2>
            </div>
            <div className={styles.content}>{current?.productDescription}</div>
          </div>
        </Container>
      )}

      {current?.component && current?.component}
      {current?.productId !== 'all' && <AllProduct />}
      <ContactBox />
    </div>
  )
}
