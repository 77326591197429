// import classNames from 'classnames'
import Container from 'components/Container'
import React from 'react'
// import { useTable } from 'react-table'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import 'react-tabs/style/react-tabs.css'

import styles from './ProductMixedConcrete.module.scss'

// const SpecA = () => {
//   const QUALITY_SPECS_DATA = React.useMemo(
//     () => [
//       {
//         qualityLevel: 'Lean',
//         qubic: '-',
//         cylinder: '-',
//       },
//       {
//         qualityLevel: '18/15',
//         qubic: '180 ksc.',
//         cylinder: '150 ksc.',
//       },
//       {
//         qualityLevel: '21/18',
//         qubic: '210 ksc.',
//         cylinder: '180 ksc.',
//       },
//       {
//         qualityLevel: '24/21',
//         qubic: '240 ksc.',
//         cylinder: '210 ksc.',
//       },
//       {
//         qualityLevel: '28/24',
//         qubic: '280 ksc.',
//         cylinder: '240 ksc.',
//       },
//       {
//         qualityLevel: '32/28',
//         qubic: '320 ksc.',
//         cylinder: '280 ksc.',
//       },
//       {
//         qualityLevel: '35/30',
//         qubic: '350 ksc.',
//         cylinder: '300 ksc.',
//       },
//       {
//         qualityLevel: '38/32',
//         qubic: '380 ksc.',
//         cylinder: '320 ksc.',
//       },
//       {
//         qualityLevel: '40/35',
//         qubic: '400 ksc.',
//         cylinder: '350 ksc.',
//       },
//       {
//         qualityLevel: '45/40',
//         qubic: '450 ksc.',
//         cylinder: '400 ksc.',
//       },
//     ],
//     []
//   )

//   const QUALITY_SPECS_COLUMNS = React.useMemo(
//     () => [
//       {
//         Header: 'ชั้นคุณภาพ',
//         accessor: 'qualityLevel',
//         className: styles.headerMain,
//       },

//       {
//         Header: 'กำลังต้านทางแรงอัดคอนกรีตที่อายุ 28 วัน',
//         columns: [
//           {
//             Header: 'ทรงลูกบาศก์มาตรฐาน',
//             accessor: 'qubic',
//           },
//           {
//             Header: 'ทรงกระบอกมาตรฐาน',
//             accessor: 'cylinder',
//           },
//         ],
//       },
//     ],
//     []
//   )

//   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
//     columns: QUALITY_SPECS_COLUMNS,
//     data: QUALITY_SPECS_DATA,
//   } as any)
//   return (
//     <table {...getTableProps()} className={styles.table}>
//       <thead>
//         {headerGroups.map((headerGroup) => (
//           <tr {...headerGroup.getHeaderGroupProps()}>
//             {headerGroup.headers.map((column) => (
//               <th {...column.getHeaderProps()} className={classNames(styles.cell, styles.headerCell)}>
//                 {column.render('Header')}
//               </th>
//             ))}
//           </tr>
//         ))}
//       </thead>
//       <tbody {...getTableBodyProps()}>
//         {rows.map((row, index) => {
//           prepareRow(row)
//           return (
//             <tr className={classNames(styles.row, index % 2 === 0 ? styles.odd : styles.even)} {...row.getRowProps()}>
//               {row.cells.map((cell) => {
//                 return (
//                   <td className={styles.cell} {...cell.getCellProps()}>
//                     {cell.render('Cell')}
//                   </td>
//                 )
//               })}
//             </tr>
//           )
//         })}
//       </tbody>
//     </table>
//   )
// }

// const SpecB = () => {
//   const QUALITY_SPECS_DATA = React.useMemo(
//     () => [
//       {
//         type: 'คอนกรีตหยาบ (Lean)',
//         slump: '7.5 ± 2.5',
//       },
//       {
//         type: 'ถนน คสล.',
//         slump: '7.5 ± 2.5',
//       },
//       {
//         type: 'คาน พื้น ฐานราก',
//         slump: '10.0 ± 2.5',
//       },
//       {
//         type: 'เสา ผนังบางๆ',
//         slump: '12.5 ± 2.5',
//       },
//       {
//         type: 'งานเทกันน้ำ (Topping)',
//         slump: '12.5 ± 2.5',
//       },
//       {
//         type: 'คอนกรีตปั้ม',
//         slump: '12.5 ± 2.5',
//       },
//     ],
//     []
//   )

//   const QUALITY_SPECS_COLUMNS = React.useMemo(
//     () => [
//       {
//         Header: 'ค่ายุบตัวที่เหมาะสมสำหรับงานคอนกรีตประเภทต่างๆ',
//         columns: [
//           {
//             Header: 'ประเภทของงานคอนกรีต',
//             accessor: 'type',
//           },
//           {
//             Header: 'ค่ายุบตัว (cm.)',
//             accessor: 'slump',
//           },
//         ],
//       },
//     ],
//     []
//   )

//   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
//     columns: QUALITY_SPECS_COLUMNS,
//     data: QUALITY_SPECS_DATA,
//   } as any)
//   return (
//     <table {...getTableProps()} className={styles.table}>
//       <thead>
//         {headerGroups.map((headerGroup) => (
//           <tr {...headerGroup.getHeaderGroupProps()}>
//             {headerGroup.headers.map((column) => (
//               <th {...column.getHeaderProps()} className={classNames(styles.cell, styles.headerCell)}>
//                 {column.render('Header')}
//               </th>
//             ))}
//           </tr>
//         ))}
//       </thead>
//       <tbody {...getTableBodyProps()}>
//         {rows.map((row, index) => {
//           prepareRow(row)
//           return (
//             <tr className={classNames(styles.row, index % 2 === 0 ? styles.odd : styles.even)} {...row.getRowProps()}>
//               {row.cells.map((cell) => {
//                 return (
//                   <td className={styles.cell} {...cell.getCellProps()}>
//                     {cell.render('Cell')}
//                   </td>
//                 )
//               })}
//             </tr>
//           )
//         })}
//       </tbody>
//     </table>
//   )
// }

export default function ProductMixedConcrete() {
  return (
    <div className={styles.wrapper}>
      <img className={styles.imageFluid} src={require('./assets/scg-pre-mixed.jpeg').default} alt='mixed-concrete' />
      <div className={styles.specificationWrapper}>
        <Container>
          <div className={styles.specificationContainer}>
            <h2>ผลิตภัณฑ์คอนกรีต</h2>
            <div className={styles.tab}>
              <Tabs>
                <TabList>
                  <Tab>คอนกรีตผสมเสร็จ (แบบธรรมดา)</Tab>
                  <Tab>คอนกรีตทนดินเค็ม-น้ำเค็ม</Tab>
                </TabList>
                <TabPanel>
                  <div>
                    <div className={styles.larger}>
                      <u>รายละเอียด</u>
                    </div>
                    <p>
                      คอนกรีตที่ใช้งานง่ายมีความสามารถกระจายตัวได้อย่างมีประสิทธิภาพ
                      และสามารถได้การไหลตัวในระดับสูงด้วยปริมาณน้ำจำนวนน้อย จึงทำให้เทคอนกรีตได้ง่าย สะดวกขึ้น
                      งานเสร็จเร็วขึ้น ทำให้ลดโอกาสในการเกิดปัญหาการแตกร้าว เป็นฝุ่น เป็นโพรง กำลังอัดตก
                      เนื่องจากการเติมน้ำ นอกจากนี้ยังสามารถถอดแบบได้เร็วขึ้น และได้ผลงานมีความสวยงามเรียบร้อย
                      มีผิวที่เรียบเนียน เหมาะสำหรับงานประเภท ทุกโครงสร้าง เช่น ฐานราก เสา คาน พื้น
                    </p>
                    <div className={styles.larger}>
                      <u>คุณสมบัติ</u>
                    </div>
                    <p>
                      - มีความไหลลื่นสูง ทำงานง่าย - ถอดแบบได้เร็วขึ้น เมื่อเทียบกับคอนกรีตมาตรฐาน - ผิวเรียบเนียน
                      สวยงาม
                      <br />- มีค่ายุบตัว 15-20 ซม.
                    </p>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    <div className={styles.larger}>
                      <u>รายละเอียด</u>
                    </div>
                    <p>
                      คอนกรีตที่ออกแบบมา เพื่อให้มีคุณสมบัติ ต้านทานการซึมผ่านของ คลอไรด์ และ ซัลเฟต ซึ่งสะสมอยู่ในดิน
                      หรือบริเวณแหล่งน้ำใกล้เคียง เพื่อการใช้งานภาคใต้โดยเฉพาะ โดยใช้ปูนซีเมนต์สูตรทนน้ำทะเลจาก SCG
                      ป้องกันปฏิกิริยาการเกิดสนิมของเหล็กเสริมและการเสื่อมสภาพของคอนกรีต
                    </p>
                    <div className={styles.larger}>
                      <u>เหมาะสำหรับงานประเภท</u>
                    </div>
                    <p>
                      โครงสร้างที่สัมผัสกับ คลอไรด์ และ ซัลเฟต ที่สะสมอยู่ในดิน หรือบริเวณแหล่งน้ำใกล้เคียง
                      โดยเฉพาะภาคใต้ของประเทศไทย คุณสมบัติ -ต้านทานการซึมผ่านของ คลอไรด์ และ ซัลเฟต
                      โดยสามารถต้านทานการซึมผ่านของ คลอไรด์ ได้ถึงระดับความเข้มข้น 10,000 – 27,000 ppm
                      และต้านทานซัลเฟตได้ถึงระดับความเข้มข้น 2,200 ppm
                    </p>
                    <div className={styles.larger}>
                      <u>คำแนะนำในการใช้งาน และข้อควรระวัง</u>
                    </div>
                    <p>
                      -ไม่ควรเติมน้ำเพื่อให้คอนกรีตมีความเหลวมากขึ้น เพราะจะทำให้คุณสมบัติคอนกรีต
                      ไม่เป็นไปตามที่ออกแบบไว้
                    </p>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
          <div className={styles.specificationContainer}>
            <h2>รายละเอียด</h2>
            <div className={styles.tab}>
              <Tabs>
                <TabList>
                  <Tab>Lean</Tab>
                  <Tab>180 ksc.</Tab>
                  <Tab>210 ksc.</Tab>
                  <Tab>240 ksc.</Tab>
                  <Tab>280 ksc.</Tab>
                  <Tab>320 ksc.</Tab>
                  <Tab>380 ksc.</Tab>
                  <Tab>400 ksc.</Tab>
                  <Tab>450 ksc.</Tab>
                </TabList>
                <TabPanel>
                  <div>
                    Lean หรือคอนกรีตหยาบ คือ คอนกรีตที่ค่อนข้างรับน้ำหนักได้ต่ำ
                    ใช้สำหรับงานเทปรับระดับพื้นดินที่ไม่เรียบ รวมถึงช่วยป้องกันการสัมผัสกันโดยตรงระหว่างดินกับคอนกรีตสด
                    โดยเฉพาะโครงสร้างที่อยู่ต่ำกว่าหรือใกล้กับระดับดินและงานหล่อโครงสร้าง คอนกรีตเสริมเหล็ก ทั้งนี้
                    การเทคอนกรีตหยาบในบ้านเรานิยมเรียกกันว่า การเทลีน โดยการเทลีนจะเทลงไปบนพื้นดินหนาประมาณ 5-10
                    เซนติเมตร เพื่อปรับหน้าดินให้เรียบและป้องกันการเปลี่ยนต่ำแหน่งของเหล็กที่เกิดจากการไหลของหน้าดิน
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    คอนกรีตกำลังอัด 180 ksc. หรือคอนกรีตที่สามารถรับแรงกดทับในแนวดิ่งได้ 180 กิโลกรัมต่อตารางเซนติเมตร
                    เหมาะสำหรับการใช้งานที่ไม่รับแรงกดทับมาก เช่น
                    <ul>
                      <li>การเทพื้นลานนอกบ้าน</li>
                      <li>ลานจอดรถเก๋ง รถกระบะ</li>
                      <li>ลานโรงเรียน</li>
                      <li>ลานอเนกประสงค์</li>
                    </ul>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    คอนกรีตกำลังอัด 210 ksc.หรือคอนกรีตที่สามารถรับแรงกดทับในแนวดิ่งได้ 210 กิโลกรัมต่อตารางเซนติเมตร
                    เหมาะสำหรับสร้างโครงสร้างขนาดเล็กและขนาดกลางทั่วไป เช่น
                    <ul>
                      <li>พื้นโรงงาน</li>
                      <li>ถนน</li>
                      <li>อาคาร</li>
                      <li>บ้านพักอาศัย</li>
                      <li>สำนักงาน</li>
                      <li>คอนโด</li>
                      <li>ตึกสูง</li>
                      <li>ลาน</li>
                    </ul>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    คอนกรีตกำลังอัด 240 ksc. หรือคอนกรีตที่สามารถรับแรงกดทับในแนวดิ่งได้ 240 กิโลกรัมต่อตารางเซนติเมตร
                    สามารถกันน้ำได้ในระดับหนึ่งโดยใช้น้ำยากันซึมเข้าช่วย
                    เหมาะสำหรับการใช้งานในอาคารขนาดเล็กที่ได้มาตรฐานสูง และการใช้งานในถนนบางประเภท เช่น
                    <ul>
                      <li>งานถนนที่มีการจราจรน้อย</li>
                      <li>ลานจอดรถเก๋ง รถกระบะ</li>
                      <li>ถนนเทศบาล อบต. อบจ.</li>
                      <li>พื้นดาดฟ้า</li>
                      <li>พื้นห้องน้ำ</li>
                      <li>อาคารพาณิชย์ 2-4 ชั้น</li>
                      <li>บ้านชั้นเดียว, บ้าน 2 ชั้น</li>
                      <li>ฐานรากบ้าน</li>
                    </ul>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    คอนกรีตกำลังอัด 280 ksc. หรือคอนกรีตที่สามารถรับแรงกดทับในแนวดิ่งได้ 280 กิโลกรัมต่อตารางเซนติเมตร
                    เหมาะสำหรับการใช้ในอาคารขนาดกลาง และงานถนนที่ใหญ่ขึ้นตามขนาดของการใช้งาน เช่น
                    <ul>
                      <li>อาคารพาณิชย์ 2-4 ชั้น</li>
                      <li>โรงแรม</li>
                      <li>อาคารสำนักงาน 2-4 ชั้น</li>
                      <li>อาคารเรียน 4-6 ชั้น</li>
                      <li>อาคารที่พักอาศัย</li>
                      <li>ถนนเทศบาล อบต. อบจ.</li>
                    </ul>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    คอนกรีตกำลังอัด 320 ksc. หรือคอนกรีตที่สามารถรับแรงกดทับในแนวดิ่งได้ 320 กิโลกรัมต่อตารางเซนติเมตร
                    เป็นคอนกรีตที่ค่อนข้างแข็งแรง สามารถรับแรงกดทับได้มาก เหมาะสำหรับอาคารสูงที่มีเหล็กมาก
                    และงานถนนสายหลัก เช่น
                    <ul>
                      <li>ถนนกรมทางหลวง</li>
                      <li>พื้นโกดังเก็บสินค้า</li>
                      <li>สระว่ายน้ำ</li>
                      <li>พื้นดาดฟ้า</li>
                      <li>อาคารที่พักอาศัย</li>
                      <li>อาคารเรียน 4-6 ชั้น</li>
                      <li>โรงแรมสูง</li>
                      <li>คอนโดมิเนียม 4-8 ชั้น</li>
                    </ul>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    คอนกรีตกำลังอัด 380 ksc. หรือคอนกรีตที่สามารถรับแรงกดทับในแนวดิ่งได้ 380 กิโลกรัมต่อตารางเซนติเมตร
                    เป็นคอนกรีตที่สามารถรองรับงานได้เกือบทุกแบบตั้งแต่งานอาคารขนาดกลางไปจนถึงอาคารสูงที่มีเหล็กหนาแน่นมาก
                    ประเภทของงานที่รองรับ ได้แก่ เช่น
                    <ul>
                      <li>โรงแรมสูง</li>
                      <li>คอนโดมิเนียม 4-8 ชั้น</li>
                      <li>ศูนย์การค้าต่างๆ</li>
                      <li>ผนังเขื่อน</li>
                      <li>อาคารเรียน 4-6 ชั้น</li>
                      <li>ถนนกรมทางหลวง, สำนักงานทางหลวงชนบท</li>
                      <li>สระว่ายน้ำ</li>
                    </ul>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    คอนกรีตกำลังอัด 400 ksc. หรือคอนกรีตที่สามารถรับแรงกดทับในแนวดิ่งได้ 400 กิโลกรัมต่อตารางเซนติเมตร
                    เหมาะสำหรับงานที่ต้องการความแข็งแรงเป็นพิเศษตามขนาดของการใช้งานในอุตสาหกรรมต่างๆ ได้แก่
                    <ul>
                      <li>อาคารที่สูงมากกว่า 8 ชั้นขึ้นไป</li>
                      <li>ใช้เทเพื่อการผลิตแผ่นพื้น เสา คาน</li>
                      <li>ลานบิน</li>
                      <li>ผนังเขื่อน</li>
                    </ul>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div>
                    คอนกรีตกำลังอัด 450 ksc. หรือคอนกรีตที่สามารถรับแรงกดทับในแนวดิ่งได้ 450 กิโลกรัมต่อตารางเซนติเมตร
                    เหมาะสำหรับงานที่ต้องการความแข็งแรงเป็นพิเศษตามขนาดของการใช้งานในอุตสาหกรรมต่างๆ
                    <ul>
                      <li>อาคารที่สูงมากกว่า 8 ชั้นขึ้นไป</li>
                      <li>ใช้เทเพื่อการผลิตแผ่นพื้น เสา คาน</li>
                      <li>ลานบิน</li>
                      <li>ผนังเขื่อน</li>
                    </ul>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
