import React from 'react'
import { useTable } from 'react-table'
import _ from 'lodash'
import classNames from 'classnames'

import styles from './ProductConcretePiles.module.scss'

const SpecA = () => {
  const QUALITY_SPECS_DATA = React.useMemo(
    () => [
      {
        width: '35 x 35',
        length: '6 - 28',
      },
      {
        width: '40 x 40',
        length: '6 - 28',
      },
      {
        width: '45 x 45',
        length: '6 - 28',
      },
    ],
    []
  )

  const QUALITY_SPECS_COLUMNS = React.useMemo(
    () => [
      {
        Header: (
          <p>
            ขนาดหน้าตัด
            <br />
            <span>กว้าง (cm.) x หนา (cm.)</span>
          </p>
        ),
        accessor: 'width',
        className: styles.headerMain,
      },
      {
        Header: (
          <p>
            ความยาว
            <br />
            <span>(m.)</span>
          </p>
        ),
        accessor: 'length',
        className: styles.headerMain,
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = useTable({
    columns: QUALITY_SPECS_COLUMNS,
    data: QUALITY_SPECS_DATA,
  } as any)
  return (
    <div>
      <p>เสาเข็มตัวไอ</p>
      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const isHidden = _.first(_.get(headerGroup, 'headers'))?.Header === 'hidden'
            return (
              !isHidden && (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className={classNames(styles.headerCell)}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              )
            )
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <tr className={classNames(styles.row, index % 2 === 0 ? styles.odd : styles.even)} {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className={styles.cell} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group, index) => {
            return (
              index !== 0 && (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => {
                    return (
                      <td
                        className={classNames(
                          styles.cell,
                          styles.footerCell,
                          column.Footer === 'ทุกชนิดทุกขนาด ความยาวตามสั่ง' && styles.center
                        )}
                        {...column.getFooterProps()}
                      >
                        {column.render('Footer')}
                      </td>
                    )
                  })}
                </tr>
              )
            )
          })}
        </tfoot>
      </table>
    </div>
  )
}

export default SpecA
